import React from "react";
import ProfilePic from "../Assets/ONDC.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
         <p className="primary-subheading">Advantages</p> 
        <h1 className="primary-heading">Unlock the ONDC Advantage:</h1>
        <p className="primary-text">
        Experience the power of the government-backed initiative, ONDC, as we bring it to your fingertips. ONDC has transformed the way you shop and avail services, connecting you to a vast network of trusted merchants and service providers. 
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <img src={ProfilePic} alt="" style={{width:150 , height:150}}/>
        <p>
        With servve, you can enjoy the convenience, reliability, and transparency that ONDC offers, ensuring a seamless experience every time.
        </p>
       
        <h2>SERVVE</h2>
      </div>
    </div>
  );
};

export default Testimonial;
