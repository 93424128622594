import React from "react";

import BannerImage from "../Assets/home.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
            One-Stop Destination for Food, Grocery, Fashion , Auto Rides and
            more ...
          </h1>
          <p className="primary-text">
            Empowered by the government-backed initiative called ONDC.{" "}
          </p>
          <p className="primary-text">
            We believe in simplifying your life and enhancing your daily
            experiences by offering a wide range of products and services at
            your fingertips. With servve, you can effortlessly purchase food,
            groceries, clothes, and even book auto rides, all within a single
            app.
          </p>
          <button className="secondary-button">
            Order Now <FiArrowRight />{" "}
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
