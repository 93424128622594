import React from "react";
import Logo from "../Assets/logo.png";
import ONDC from "../Assets/ONDC.png";
import plus from "../Assets/handshake.png"
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
     <div>
       <div style={{display:"flex" ,flexDirection:'row'}}>
       <div className="footer-logo-container">
          <img src={Logo} alt="" style={{width:170 , height:150}}/>
        </div>
        <div className="footer-logo-container">
          <img src={plus} alt="" style={{width:40 , height:40 ,marginTop:40 , marginRight:20}}/>
        </div>
        <div className="footer-logo-container">
          <img src={ONDC} alt="" style={{width:100 , height:120}}/>
        </div>
       </div>
        
     

      <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>
        </div>

      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>Qualtiy</span>
          <span>Help</span>
          <span>Share</span>
          <span>Carrers</span>
          <span>Testimonials</span>
          <span>Work</span>
        </div>
        <div className="footer-section-columns">
          {/* <span>244-5333-7783</span> */}
          <span>hello@servve.shop</span>
          <span>enquiry@servve.shop</span>
          <span>contact@servve.shop</span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
