import React from "react";
import PickMeals from "../Assets/pick.png";
import ChooseMeals from "../Assets/order.png";
import DeliveryMeals from "../Assets/delivered.png";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Pick Anything",
      text: "Our extensive network of trusted merchants ensures that you have access to a vast selection of products and services, all easily accessible within a single, user-friendly app.",
    },
    {
      image: ChooseMeals,
      title: "Order you Like",
      text: "Browse, compare, and purchase clothes, food, groceries, and even book auto rides without leaving the servve platform. All at one place with millions of options available . ",
    },
    {
      image: DeliveryMeals,
      title: "Safe & Secure",
      text: "Servve leverages cutting-edge technology to ensure your data and transactions are protected at all times. With our secure payment gateway and transparent pricing, you can shop with confidence.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
        With servve, you no longer need to juggle multiple apps for your everyday needs. Whether you're craving a delicious meal, in need of fresh groceries, looking to upgrade your wardrobe, or require a hassle-free auto ride, servve has got you covered.

        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
